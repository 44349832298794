import React from 'react';
import ErrorSection from './section-components/error';

const Error = () => {
    return <div>
        <ErrorSection />
    </div>
}

export default Error

